<template>
  <div class="back_two" @click="handleDetail">
    <div  style="display: block">
      <img src="~@/assets/img/invite_friends/img_biaoti1.png" class="img" />
      <div class="rules_div" style="display: block">
        <img src="~@/assets/img/invite_friends/botton_mingxi.png" class="img_mingxi" />
      </div>
    </div>
    <div class="two_div">
      <div class="two_half">
        <div class="font_size_34_F">{{ recommendInfo.money }}</div>
        <div class="font_size14_3">累计收益(元)</div>
      </div>
      <div class="two_half">
        <div class="font_size_34_F">{{ recommendInfo.number }}</div>
        <div class="font_size14_3">成功邀请(人)</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["recommendInfo"],
  data() {
    return {};
  },
  methods: {
    handleDetail() {
      this.$router.push({
        path: "/invite_detail",
        query: {},
      });
    },
  },
};
</script>
